@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Colors */
:root {
  --robin-egg-blue: #52d1dcff;
  --feldgrau: #475b5aff;
  --battleship-gray: #8d8e8eff;
  --cadet-gray: #a3a9aaff;
  --french-gray: #bbbbbfff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  background-color: var(--robin-egg-blue);
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

.logo {
  font-weight: bolder;
  font-size: 2rem;
}
h1 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.clockInfo {
  margin-bottom: 15px;
}

.shiftContainer,
.statsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid var(--cadet-gray);
  margin: 13px 0px;
  border-radius: 5px;
}
.statsContainer {
  margin-inline: 10px;
}

.shiftContainer {
  margin-inline: 13%;
}

.shiftGroup {
  padding-bottom: 70px;
}

.shiftInfo {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--cadet-gray);
  margin-bottom: 60px;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: var(--battleship-gray);
}

.hourly {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1.5rem;
}

.form {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: var(--feldgrau);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-block {
  width: 50%;
  margin-bottom: 20px;
  margin-inline: auto;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.openModalBtn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: var(--feldgrau);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.submitModalBtn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: var(--feldgrau);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  align-items: center;
  display: block;
  justify-content: center;
  margin-left: 5px;
}

.payInput {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  appearance: button;
  align-items: center;
  display: block;
  justify-content: center;
  width: 50%;
  margin-bottom: 5px;
  margin-inline: auto;
}
.updatePayForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.updatePayForm label {
  text-align: center;
}

.selector {
  position: relative;
  width: 60%;
  background-color: var(--feldgrau);
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}
.selector-item {
  position: relative;
  flex-basis: calc(70% / 3);
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.selector-item_radio {
  appearance: none;
  display: none;
}
.selector-item_label {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  line-height: 400%;
  font-weight: 900;
  transition-duration: 0.5s;
  transition-property: transform, color, box-shadow;
  transform: none;
}
.selector-item_label:hover {
  cursor: pointer;
}
.selector-item_radio:checked + .selector-item_label {
  background-color: var(--battleship-gray);
  color: black;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
}

.radioContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .boxes {
    grid-template-columns: 1fr;
  }

  .form {
    width: 90%;
  }

  .heading p {
    font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  .shiftContainer {
    flex-direction: column;
    margin-inline: 10px;
    padding-bottom: 7px;
  }
  .payBtn {
    margin-top: 5px;
  }
  .selector {
    width: 90%;
  }
}
